import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Link} from "react-router-dom";
import './navlist.css';

export const NavList = () => {
	const [currentPage, setCurrentPage] = useState("");
	return (
    <div className="navlist">
		<ul>
			<li className={(window.location.pathname === '/' || currentPage === "About" ? 'active' : '')}>
				<Link to="/" style={{textDecoration: "none"}}>
					<a onClick={()=> setCurrentPage("About")}>About</a>
				</Link>
			</li>
			<li  className={(window.location.pathname==="/projects" ? 'active' : '')}>
				<Link to="/projects" style={{textDecoration: "none"}}>
					<a onClick={()=> setCurrentPage("Projects")}>Software</a>
				</Link>
			</li>
			<li  className={(window.location.pathname === '/gamedev' ? 'active' : '')}>
				<Link to="/gamedev" style={{textDecoration: "none"}}>
					<a onClick={()=> setCurrentPage("GameDev")}>Computer Graphics</a>
				</Link>
			</li>
			{/* <li className={(currentPage==="Experience" ? 'active' : '')}>
				<Link to="/experience" style={{textDecoration: "none"}}>
					<a onClick={()=> setCurrentPage("Experience")}>Experience</a>
				</Link>
			</li> */}
		</ul>
	</div>
  );
};
