import React, { useState } from "react";
import './about.css'
import linkedIn from '../../assets/websiteIcons/linkedIn.png'
import github from '../../assets/websiteIcons/github.PNG'
import resumeButton from '../../assets/websiteIcons/resumeButton.PNG'
import resume from '../../assets/RoseKellyResume.pdf'

export const About = () => {
	return (
    <div class="aboutTextDiv">
        <a>Hi! I'm Rose.</a>
        <br></br>
        <br></br>
        <a>I'm a software engineer with 3 years of tech industry experience and many more spent hacking away on personal projects. </a>
        <br></br>
        <br></br>
        <a>I love learning new technologies and stretching their capabilities in unconventional applications. </a>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <a style={{float:"right"}} href={resume} target="_blank"><img src={resumeButton} alt="Rose's Resume"
        style={{width: "calc(4.3vw + 4.3vh)", height: "calc(1.5vw + 1.5vh)",
        marginTop: "calc(0.3vw + 0.3vh)"}}/></a>
        <a style={{float:"right"}} href="https://www.linkedin.com/in/rose-kelly-b480b01a8/" target="_blank"><img src={linkedIn} alt="Rose's linkedIn"
         style={{width: "calc(2vw + 2vh)", height: "calc(2vw + 2vh)", marginRight: "1vw"}}/></a>
        <a style={{float:"right"}} href="https://github.com/rosekelly6400" target="_blank"><img src={github} alt="Rose's Github" 
          style={{ width: "calc(1.95vw + 1.95vh)", height: "calc(1.9vw + 1.9vh)",
          paddingBottom: "calc(0.1vw + 0.1vh)", marginRight: "1vw"}}/></a>
        
	</div>
  );
};