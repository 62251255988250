import React, { useState, useEffect, useRef } from "react";
import {useParams} from "react-router-dom"

import '../projects/projects.css';
import KFLogo from '../../assets/KyokosFriends/KyokosFriendsLogo.png'
import KFSS from '../../assets/KyokosFriends/KyokosFriendsSS.png'

import femaleSculptFace from '../../assets/BodySculpt/femaleSculptFace.png'
import turntable from '../../assets/BodySculpt/turntable.gif'
import bodyAngle from '../../assets/BodySculpt/bodyAngleStill.png'
import noPaintFace from '../../assets/BodySculpt/noPaintWF.png'
import WFbody from '../../assets/BodySculpt/WF.png'

import sakeBottleRender from '../../assets/sakeBottleRender.jpg'

import bikerAnim from '../../assets/bikerAnim/bikerIsabelle.gif'

import PeskiChart from '../../assets/Peski/PeskiTranspilerChart.png'
import PeskiSample from '../../assets/Peski/PeskiCodeSample.png'

import UIOpening from '../../assets/MayaPlugin/UIOpening.PNG'
import UIMapExamples from '../../assets/MayaPlugin/UIMapExamples.PNG'
import mapColors from '../../assets/MayaPlugin/mapColors.PNG'
import deskSpecs from '../../assets/MayaPlugin/deskSpecs.PNG'
import libraryExample from '../../assets/MayaPlugin/libraryExample.PNG'

import level4 from '../../assets/GMTK/level4.png'
import level8 from '../../assets/GMTK/level8.png'
import level8Move from '../../assets/GMTK/Level8Move.gif'
import mouseRig from '../../assets/GMTK/mouseRig.PNG'

import meadow from '../../assets/VRGJ/meadow.png'
import cave from '../../assets/VRGJ/cave.png'
import crystalShader from '../../assets/VRGJ/crystalShader.gif'
import oceanShader from '../../assets/VRGJ/oceanShader.gif'
import tower1 from '../../assets/VRGJ/tower1.png'
import tower2 from '../../assets/VRGJ/tower2.png'
import towerExtr from '../../assets/VRGJ/towerExtr.png'

import mountainEnv from "../../assets/mountainEnv/mountainEnv.png"
import houdiniLandscape from "../../assets/mountainEnv/HoudiniMountainEnv.png"
import oneColorLandscape from "../../assets/mountainEnv/oneColorLandscape.png"
import texturedLandscape from "../../assets/mountainEnv/texturedLandscape.png"

import {ProjectSummary} from '../../components/projectSummary/index.js'

export const GameDev = () => {
	const params = useParams()

	useEffect(() => {
		if(Object.keys(params))	{
			const projectH = (0.25*window.innerHeight + 0.281*window.innerWidth)
			let scrollDistance = 0
			if (params.scroll === "Maya-Plugin") scrollDistance = projectH
			if (params.scroll === "Kyokos-Friends") scrollDistance = projectH*2
			window.scrollTo({ top: scrollDistance - 30, behavior: "smooth" })
		}
	}, []);
	const [currProject, setCurrProject] = useState("projectSelectionCol");

	return (
    <div class="projects">
		<ProjectSummary title={"Procedural Mountain Environment (Houdini + UE5)"} imgs={[mountainEnv, houdiniLandscape, oneColorLandscape, texturedLandscape]}  
		description1={"I generated and eroded the landscape in Houdini using HeightFields, and then created masks for each material type (grass, snow, rock, etc)."}
		description2={"I brought the landscape into UE5 and then textured it using the mask maps from Houdini and Megascans materials using a Layer Blend Blueprint. I then procedurally generated foliage using Megascans assets for a photorealistic look."}
		skills={[ {type: "gamedevtool", skill: "Houdini"}, {type: "gamedevtool", skill: "Unreal Engine 5"}]}/>

		<ProjectSummary title={"Maya Plugin GUI for Environment Generation"} imgs={[mapColors, libraryExample, UIOpening, UIMapExamples, deskSpecs]}  
		description1={"A Maya Plugin UI made with Python and C++."}
		description2={"This plugin allows you to generate environments from PNG pixel maps. After the plugin reads the map you can assign each color on the map to an object file (.obj, .fbx, .ma) and the object will load in the viewport over a tile that is the size of one pixel in the environment. Now the user can adjust scale, rotation and position relative to the tile. These settings determine how the plugin will place that object in the environment over each pixel location that its color appears."}
		description3 ={"You can also input a second pixel map for the topography of your environment. This will create the plane that makes up the 'floor' of the environment and all object's Z placement will  follow its topography."}
		links={[{description: "Github Repo:", url: "https://github.com/rosekelly6400/environment-generator-Maya-plugin"}]}
		skills={[{type: "language", skill: "Python"}, {type: "language", skill: "C++"}, {type: "gamedevtool", skill: "Maya"}, {type: "gamedevtool", skill: "Unreal Engine 4"}]}/>
		
		<ProjectSummary title={"HackReality Game Jam 2020 - VR Climbing Game"} imgs={[cave, oceanShader, crystalShader, towerExtr, tower1, tower2]} 
		ytVideos={["https://www.youtube.com/embed/OFcM54nKDOc"]}
		description1={"A VR game made for the week-long 2021 HackReality VR Hackathon where I was the 3D Modeler and Technical Artist for my team."}
		description2 ={"I created and textured the models for the environments in Maya. Because this was a VR game, there was an emphasis on making the environments as low triangle count as possible without sacrificing style to optimize the performance of the game. This influenced the low poly style of the assets as well as my overall design of the world. After the models were made, I exported them to UE4 and then prepared the models in UE4 for our gameplay programmers. This included fixing flaws in model geometry that only became obvious in Unreal, creating shader blueprints for the crystals and ocean, and setting up lighting, collision and fog for the environment."}
		description3 ={""}
		skills={[{type: "gamedevtool", skill: "Unreal Engine 4"}, {type: "gamedevtool", skill: "Maya"}]}
		links={[{description: "DevPost:", url: "https://devpost.com/software/vr-climbing-and-puzzle-game"}]}/>
		
		
		<ProjectSummary imgs={[noPaintFace, WFbody, turntable, femaleSculptFace, bodyAngle]} 
		title={"Realistic Female Anatomy Sculpt and Paint"}  
		description1={"I created a rough human body mesh in Maya and then sculpted and painted it in Mudbox into using photos of a fashion model for reference."}
		description2={"This was done as an open ended sculpting project in an Advanced 3D Modeling and Animation Course I took my senior year. "}
		description3 ={""}
		skills={[{type: "gamedevtool", skill: "Mudbox"}, {type: "gamedevtool", skill: "Maya"}]}/>
		
		<ProjectSummary title={"GMTK Game Jam 2021"}  imgs={[level4, level8Move, mouseRig]} 
		description1={"In this weekend long game jam, I worked as the 3D Modeler/Technical Artist on for my team on this Fire Emblem style strategy game."}
		description3 ={"I created the character models from scratch in Maya, rigged them, and animated them with attack and movement animations before transferring the models and animations to Unity for our team's gameplay programmers. Our team implemented a very simple toon shader in Unity to give the game a 2D look."}
		links={[{description: "itch.io:", url: "https://centipeda.itch.io/feline-formations"}]}
		skills={[{type: "gamedevtool", skill: "Maya"}, {type: "gamedevtool", skill: "Unity"}]}/>
		
		<ProjectSummary imgs={[bikerAnim]} title={"Rigging and Animation Project"}  
		description1={"Sophomore year VFX course open ended animation project. [unrigged character and environment models found online]"}
		description2 ={"I rigged the character model and animated her movement and facial expression changes in Maya. I also set up the lighting and camera movement for this scene. Rendered using Arnold."}
		skills={[{type: "gamedevtool", skill: "Maya"}]}/>

		<ProjectSummary imgs={[sakeBottleRender]} title={"Sake Bottle 3D Model (my first Maya project!)"}  
		description1={"Scene created from scratch using Maya. This was for a Sophomore year VFX course open ended modeling project with the prompt \"Render a drink of your choice.\""}
		description2={"I made the meshes for all objects in the scene and I also created the materials and set up the lighting for the render. (used images found online for the Sky Dome Light background and to make materials for the wood table and the sake bottle label. I rendered the scene using Arnold."}
		description3 ={""}
		skills={[{type: "gamedevtool", skill: "Maya"}, {type: "gamedevtool", skill: "Arnold"}]}/>
	</div>
  );
};